import { Link } from 'gatsby'
import React from 'react'

import { ArrowBackIconComponent, FacebookIconComponent, TwitterIconComponent, LinkedinIconComponent } from '@/icons'
import { Label, Heading, ButtonPrimmary } from '@/uikit'

import * as styles from './style.module.css'

type BenefitType = {
  id: number
  title: string
  description: string
  image: React.SVGProps<SVGSVGElement>
}

const icon = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27 10L17.5 19.5L12.5 14.5L5 22"
      stroke="#FF714D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M21 10H27V16" stroke="#FF714D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const REQUIREMENTS = [
  {
    id: 1,
    title:
      'Village did removed enjoyed explain nor ham saw calling talking. Securing as informed declared or margaret.',
  },
  {
    id: 2,
    title:
      'Village did removed enjoyed explain nor ham saw calling talking. Securing as informed declared or margaret.',
  },
  {
    id: 3,
    title:
      'Village did removed enjoyed explain nor ham saw calling talking. Securing as informed declared or margaret.',
  },
  {
    id: 4,
    title:
      'Village did removed enjoyed explain nor ham saw calling talking. Securing as informed declared or margaret.',
  },
  {
    id: 5,
    title:
      'Village did removed enjoyed explain nor ham saw calling talking. Securing as informed declared or margaret.',
  },
]

const ROLE = [
  { id: 6, title: 'Calling offence six joy feeling. Coming merits.' },
  { id: 7, title: 'Calling offence six joy feeling. Coming merits.' },
  { id: 8, title: 'Calling offence six joy feeling. Coming merits.' },
  { id: 9, title: 'Calling offence six joy feeling. Coming merits.' },
]

const BENEFITS = [
  {
    id: 10,
    title: 'Competitive and Inflation Compensation',
    description: 'Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no suscipit quaerendum.',
    image: icon,
  },
  {
    id: 11,
    title: 'Competitive and Inflation Compensation',
    description: 'Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no suscipit quaerendum.',
    image: icon,
  },
  {
    id: 12,
    title: 'Competitive and Inflation Compensation',
    description: 'Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no suscipit quaerendum.',
    image: icon,
  },
  {
    id: 13,
    title: 'Competitive and Inflation Compensation',
    description: 'Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no suscipit quaerendum.',
    image: icon,
  },
  {
    id: 14,
    title: 'Competitive and Inflation Compensation',
    description: 'Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no suscipit quaerendum.',
    image: icon,
  },
  {
    id: 15,
    title: 'Competitive and Inflation Compensation',
    description: 'Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no suscipit quaerendum.',
    image: icon,
  },
]

export default function JobOffer() {
  return (
    <main>
      <div className="px-7.5 md:px-95 lg:px-110 xl:px-175 flex justify-center">
        <div className="max-w-1090 w-full">
          <div>
            <div className="mt-17">
              <Label>JOIN AS</Label>
            </div>
            <div className="mb-7.5 relative">
              <Link to="/careers" className="absolute -left-15 top-4 hidden md:block">
                <ArrowBackIconComponent />
              </Link>
              <div className="dark:text-gray-darker">
                <Heading>Junior Frontend Developer</Heading>
              </div>
            </div>
            <p className="mb-7.5 dark:text-text-dm font-inter font-light text-base not-italic">14 days left</p>
          </div>
          <div className="flex justify-between max-w-1090">
            <div className="max-w-770 w-full md:mr-7.5 md:p-10 md:pb-0 lg:p-20 lg:pb-0 pb-0 md:bg-white md:dark:bg-body-bg1-dm rounded-2xl">
              <div className="block md:hidden border-t dark:border-t border-text-80 border-opacity-20 dark:border-white-clear dark:border-opacity-20 py-7.5 ">
                <p className="font-inter font-light leading-bigger text-black-lighter dark:text-text-dm mb-1">
                  $5000 - $7000 gross on B2B
                </p>
                <p className="font-inter font-light leading-bigger text-black-lighter dark:text-text-dm">
                  $4000 net on CoE
                </p>
              </div>
              <div className="mb-5">
                <p className="font-inter font-semibold text-lg leading-semiBig text-text dark:text-gray-darker">
                  One advanced diverted domestic sex repeated bringing you old. Possible procured her trifling laughter
                  thoughts property she met way. Companions shy had solicitude favourable own.
                </p>
              </div>
              <p className="text-lg text-black-lighter font-light font-inter leading-semiBig mb-20 dark:text-text-dm">
                Folly words widow one downs few age every seven. If miss part by fact he park just shew. Discovered had
                get considered projection who favourable. Necessary up knowledge it tolerably. Unwilling departure
                education is be dashwoods or an. Use off agreeable law unwilling sir deficient curiosity instantly. Easy
                mind life fact with see has bore ten. Parish any chatty can elinor direct for former. Up as meant widow
                equal an share least.
              </p>
              <div>
                <h3 className="text-text dark:text-gray-darker tracking-tightest leading-38 text-28 md:text-32 mb-7.5 font-ilisarniq">
                  Requirements
                </h3>
                <ul>
                  {REQUIREMENTS.map((item: { title: string; id: number }) => (
                    <li
                      key={item.id}
                      className={`${styles.listStyle} " relative mb-5 ml-10.5 font-inter font-xl text-lg leading-semiBig text-black-lighter text-opacity-70 dark:text-text-dm "`}
                    >
                      {item.title}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-15">
                <h3 className="text-text dark:text-gray-darker tracking-tightest leading-38 text-28 md:text-32 mb-7.5 font-ilisarniq">
                  Your role
                </h3>
                <ul className={` ${styles.listStyle} " text-lg"`}>
                  {ROLE.map((item: { title: string; id: number }) => (
                    <li
                      key={item.id}
                      className={` ${styles.listStyle} " relative mb-5 ml-10.5 dark:text-text-dm text-lg "`}
                    >
                      {item.title}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-15">
                <h3 className="text-text dark:text-gray-darker tracking-tightest leading-38 text-28 md:text-32 mb-8.5 font-ilisarniq">
                  Benefits
                </h3>
                <ul className="flex flex-wrap justify-between dark:text-gray-darker mb-130">
                  {BENEFITS.map((item: BenefitType) => (
                    <li key={item.id} className="flex mb-11">
                      <div className="mr-5">{item.image}</div>
                      <div className="flex flex-col md:max-w-240 w-full">
                        <p className="font-inter font-semibold text-lg leading-medium text-text mb-2.5 dark:text-gray-darker">
                          {item.title}
                        </p>
                        <p className="text-black-lighter font-light leading-semibold text-lg text-opacity-70 dark:text-text-dm">
                          {item.description}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="border-t dark:border-t border-text-80 border-opacity-20 dark:border-white-clear dark:border-opacity-20 pt-3.5 pb-5 flex justify-between">
                <button className="border-none bg-none font-ilisarniq text-opacity-80 text-text dark:text-white-clear text-xs font-semibold leading-14">
                  Share
                </button>
                <div className="flex w-22 justify-between">
                  <FacebookIconComponent />
                  <TwitterIconComponent />
                  <LinkedinIconComponent />
                </div>
              </div>
            </div>
            <div className="md:block hidden">
              <div className="sticky top-0 flex flex-col p-7.5 rounded-lg bg-white dark:bg-body-bg1-dm min-w-200 max-w-290">
                <div className="mb-5">
                  <p className="font-inter font-semibold text-lg leading-semiBig text-text dark:text-gray-darker">
                    Junior Frontend Developer
                  </p>
                </div>
                <div className="mb-12.5">
                  <p className="font-inter font-light leading-bigger text-black-lighter dark:text-text-dm ">
                    $5000 - $7000 gross on B2B
                  </p>
                  <p className="font-inter font-light leading-bigger text-black-lighter dark:text-text-dm">
                    $4000 net on CoE
                  </p>
                </div>
                <div className="flex justify-center">
                  <div className="w-130">
                    <ButtonPrimmary>Apply now</ButtonPrimmary>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fixed bottom-0 md:hidden w-full bg-white dark:bg-body-bg1-dm pb-11 px-7.5 pt-4">
          <div className="flex justify-center">
            <ButtonPrimmary>Apply now</ButtonPrimmary>
          </div>
        </div>
      </div>
    </main>
  )
}
